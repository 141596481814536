let routes = [
    /* 工作台管理页面 start */
    {
        path: "/marketWorkbench",
        name: "marketWorkbench",
        component: () => import("../views/marketing/marketWorkbench.vue"),
        meta: { title: "市场营销工作台", keepAlive: true, comName: "marketWorkbench" },
    },
    {
        path: "/operaWorkbench",
        name: "operaWorkbench",
        component: () => import("../views/operation/operaWorkbench.vue"),
        meta: { title: "运营工作台", keepAlive: true, comName: "operaWorkbench" },
    },
    {
        path: "/customerWorkbench",
        name: "customerWorkbench",
        component: () => import("../views/service/customerWorkbench.vue"),
        meta: { title: "客服工作台", keepAlive: true, comName: "customerWorkbench" },
    },
    {
        path: "/purchaseWorkbench",
        name: "purchaseWorkbench",
        component: () => import("../views/procurement/purchaseWorkbench.vue"),
        meta: { title: "商采工作台", keepAlive: true, comName: "purchaseWorkbench" }
    },
    {
        path: "/financialWorkbench",
        name: "financialWorkbench",
        component: () => import("../views/finance/financialWorkbench.vue"),
        meta: { title: "财务工作台", keepAlive: true, comName: "financialWorkbench" }
    },
    /* 工作台管理页面 end */

    /* 法务管理 start */
    {
        path: "/aptitude",
        name: "aptitude",
        component: () => import("../views/legalDepartment/aptitude.vue"),
        meta: { title: "商品资质", keepAlive: true, comName: "aptitude" },
    },
    {
        path: "/SupplierQualification",
        name: "SupplierQualification",
        component: () => import("../views/legalDepartment/SupplierQualification.vue"),
        meta: { title: "供应商资质", keepAlive: true, comName: "SupplierQualification" },
    },
    {
        path: "/interimPeriod",
        name: "interimPeriod",
        // component: () => import("../views/legalDepartment/interimPeriod.vue"),
        meta: { title: "临期查询", keepAlive: true, disabled: true, comName: "interimPeriod" },
    },
    {
        path: "/certificate",
        name: "certificate",
        // component: () => import("../views/legalDepartment/certificate.vue"),
        meta: { title: "证书查询", keepAlive: true, disabled: true, comName: "certificate" },
    },
    {
        path: "/institution",
        name: "institution",
        // component: () => import("../views/legalDepartment/institution.vue"),
        meta: { title: "制度查询", keepAlive: true, disabled: true, comName: "institution" },
    },
    {
        path: "/legal_costComparison",
        name: "legal_costComparison",
        component: () => import("../views/legalDepartment/legal_costComparison.vue"),
        meta: { title: "招商费用管理", keepAlive: true, comName: "legal_costComparison" }
    },
    /* 法务管理 end */
    {
        path: "/service_satisfied",
        name: "service_satisfied",
        component: () => import("../views/service/service_satisfied.vue"),
        meta: { title: "咨询分析", keepAlive: true, comName: "service_satisfied" },
    },
    {
        path: "/DSR_analysis",
        name: "DSR_analysis",
        component: () => import("../views/service/DSR_analysis.vue"),
        meta: { title: "数据分析", keepAlive: true, comName: "DSR_analysis" },
    },
    /* 产生的费用 */
    {
        path: "/product",
        name: "product",
        component: () => import("../views/cost/product.vue"),
        meta: { title: "置顶费用", keepAlive: true, comName: "product" }
    },
    {
        path: "/dailyFee",
        name: "dailyFee",
        component: () => import("../views/cost/dailyFee.vue"),
        meta: { title: "软件费用(日)", keepAlive: true, comName: "dailyFee" }
    },
    {
        path: "/softwareCost",
        name: "softwareCost",
        component: () => import("../views/cost/softwareCost.vue"),
        meta: { title: "软件费用(月)", keepAlive: true, comName: "softwareCost" }
    },
    {
        path: "/throughTheTraffic",
        name: "throughTheTraffic",
        component: () => import("../views/cost/product.vue"),
        meta: { title: "直通车", keepAlive: true, comName: "throughTheTraffic", hide: true, disabled: true }
    },
    {
        path: "/otherFee",
        name: "otherFee",
        component: () => import("../views/cost/product.vue"),
        meta: { title: "其他费用", keepAlive: true, comName: "otherFee", hide: true, disabled: true }
    },
    /* 费用end */
    {
        path: "/schedule",
        name: "schedule",
        component: () => import("../views/Calendar/schedule.vue"),
        meta: { title: "日程建立", keepAlive: true, comName: "schedule" }
    },
    /*
    {
      path: "/prompting",
      name: "prompting",
      component: () => import("../views/Calendar/prompting.vue"),
      meta: { title: "我的日程", keepAlive: false, comName: "prompting" }
    },
     */
    {
        path: "/ScheduleQuery",
        name: "ScheduleQuery",
        component: () => import("../views/Calendar/ScheduleQuery.vue"),
        meta: { title: "日程查询", keepAlive: true, comName: "ScheduleQuery", levels: [{level: 31, isAdmin: true}]  }
    },
    {
        path: "/TaskTracking",
        name: "TaskTracking",
        component: () => import("../views/Calendar/TaskTracking.vue"),
        meta: { title: "日程追踪", keepAlive: true, comName: "TaskTracking" }
    },
    {
        path: "/ranking",
        name: "ranking",
        component: () => import("../views/Calendar/ranking.vue"),
        meta: { title: "排行查看", keepAlive: true, comName: "ranking" }
    },
    /* 日程 end */
    /* 运营 start */
    {
        path: "/categoryComparison",
        name: "categoryComparison",
        component: () => import("../views/operation/categoryComparison.vue"),
        meta: { title: "品类管理", keepAlive: true, comName: "categoryComparison", isAdmin: true }
    },
    {
        path: "/goods",
        name: "goods",
        component: () => import("../views/operation/goods.vue"),
        meta: { title: "宝贝ID管理", keepAlive: true, comName: "goods" }
    },
    {
        path: "/taobaoOrders",
        name: "taobaoOrders",
        component: () => import("../views/operation/taobaoOrders.vue"),
        meta: { title: "淘系订单", keepAlive: true, comName: "taobaoOrders", hide: true }
    },
    {
        path: "/logisticsInfo",
        name: "logisticsInfo",
        component: () => import("../views/operation/logisticsInfo.vue"),
        meta: { title: "快递查询", keepAlive: true, comName: "logisticsInfo" }
    },
    {
        path: "/searchErp",
        name: "searchErp",
        component: () => import("../views/operation/erp/searchErp.vue"),
        meta: { title: "商品库存查询", keepAlive: true, comName: "searchErp" }
    },
    {
        path: "/procureAlert",
        name: "procureAlert",
        component: () => import("../views/operation/erp/procureAlert.vue"),
        meta: { title: "库存及采购预警", keepAlive: true, comName: "procureAlert" }
    },
    {
        path: "/inventoryAlert",
        name: "inventoryAlert",
        component: () => import("../views/operation/erp/inventoryAlert.vue"),
        meta: { title: "库存预警", keepAlive: true, comName: "inventoryAlert" }
    },
    {
        path: "/combination",
        name: "combination",
        component: () => import("../views/operation/erp/combination.vue"),
        meta: { title: "组合装录入", keepAlive: true, comName: "combination" }
    },
    {
        path: "/combinationReg",
        name: "combinationReg",
        component: () => import("../views/operation/erp/combinationReg.vue"),
        meta: { title: "组合装管理", keepAlive: true, comName: "combinationReg" }
    },
    {
        path: "/combinationEra",
        name: "combinationEra",
        component: () => import("../views/operation/erp/combinationEra.vue"),
        meta: { title: "代发组合装管理", keepAlive: true, comName: "combinationEra" }
    },
    {
        path: "/wdtAudit",
        name: "wdtAudit",
        component: () => import("../views/operation/erp/wdtAudit.vue"),
        meta: { title: "旺店通审核", keepAlive: true, comName: "wdtAudit", isAdmin: true }
    },
    {
        path: "/marketing",
        name: "marketing",
        component: () => import("../views/operation/marketing.vue"),
        meta: { title: "营销数据", keepAlive: true, comName: "marketing" }
    },
    {
        path: "/trueOrder",
        name: "trueOrder",
        component: () => import("../views/operation/trueOrder.vue"),
        meta: { title: "SU单下载", keepAlive: true, comName: "trueOrder" }
    },
    {
        path: "/refundQuery",
        name: "refundQuery",
        component: () => import("../views/operation/refundQuery.vue"),
        meta: { title: "售后退款查询", keepAlive: true, comName: "refundQuery" }
    },
    {
        path: "/Commodity_warehouse",
        name: "Commodity_warehouse",
        component: () => import("../views/operation/Commodity_warehouse.vue"),
        meta: { title: "商品数据", keepAlive: true, comName: "Commodity_warehouse" }
    },
    {
        path: "/category",
        name: "category",
        component: () => import("../views/operation/category.vue"),
        meta: { title: "类目管理", keepAlive: true, comName: "category" }
    },
    {
        path: "/contrast",
        name: "contrast",
        component: () => import("../views/operation/contrast.vue"),
        meta: { title: "商品对比", keepAlive: true, hide: true, comName: "contrast" }
    },
    {
        path: "/operateLog",
        name: "operateLog",
        component: () => import("../views/operation/operateLog.vue"),
        meta: { title: "日志管理", keepAlive: false, comName: "operateLog" }
    },
    {
        path: "/operateLogManage",
        name: "operateLogManage",
        component: () => import("../views/operation/operateLogManage.vue"),
        meta: { title: "日志查询", keepAlive: false, comName: "operateLogManage" }
    },
    /* {
        path: "/operateLogManage1",
        name: "operateLogManage1",
        component: () => import("../views/operation/operateLogManage1.vue"),
        meta: { title: "日志查询", keepAlive: false, comName: "operateLogManage1" }
    }, */
    {
        path: "/operationsLogs",
        name: "operationsLogs",
        component: () => import("../views/operation/operationsLogs.vue"),
        meta: { title: "日志分析", keepAlive: true, comName: "operationsLogs" }
    },
    {
        path: "/costCalculate",
        name: "costCalculate",
        component: () => import("../views/operation/costCalculate.vue"),
        meta: { title: "营销费用测算", keepAlive: false, levels: [{level:6},{level: 12}], comName: "costCalculate" }
    },
    {
        path: "/panelData",
        name: "panelData",
        component: ()=>import("../views/operation/objectTracking/panelData.vue"),
        meta: { title: "目标数据录入", keepAlive: true, comName: "panelData" }
    },
    {
        path: "/targetReview",
        name: "targetReview",
        component: () => import("../views/operation/objectTracking/targetReview.vue"),
        meta: { title: "目标复盘看板", keepAlive: true, comName: "targetReview" }
    },
    {
        path: "/targetComparison",
        name: "targetComparison",
        component: ()=>import("../views/operation/objectTracking/targetComparison.vue"),
        meta: { title: "品类管理", keepAlive: true, comName: "targetComparison" }
    },
    {
        path: "/competitiveProduct",
        name: "competitiveProduct",
        component: ()=>import("../views/operation/competitiveProduct.vue"),
        meta: { title: "竞品对照表", keepAlive: true, comName: "competitiveProduct", hide: true }
    },
    {
        path: "/replenishment",
        name: "replenishment",
        component: ()=>import("../views/operation/replenishment.vue"),
        meta: { title: "补单数据核查", keepAlive: true, comName: "replenishment" }
    },
    {
        path: "/operatedAnalysis",
        name: "operatedAnalysis",
        component: () => import("../views/operation/operatedAnalysis.vue"),
        meta: { title: "运营分析", keepAlive: true, comName: "operatedAnalysis" },
    },
    {
        path: "/inquiry_loss_verification",
        name: "inquiry_loss_verification",
        component: () => import("../views/operation/inquiry_loss_verification.vue"),
        meta: { title: "询单数据核查", keepAlive: true, comName: "inquiry_loss_verification" },
    },
    {
        path: "/testAnalysis",
        name: "testAnalysis",
        component: () => import("../views/operation/testAnalysis.vue"),
        meta: { title: "复盘数据", keepAlive: true, comName: "testAnalysis", levels: [{level:6}] },
    },
    /* 运营 end */
    /* RPA数据 start 付费数据 */
    {
        path: "/paidData",
        name: "paidData",
        component: () => import("../views/rpa/paidData.vue"),
        meta: { title: "付费数据", keepAlive: true, comName: "paidData", levels: [{level:6}], hide: true },
    },
    {
        path: "/competitiveData",
        name: "competitiveData",
        component: () => import("../views/rpa/competitiveData.vue"),
        meta: { title: "竞品数据", keepAlive: true, comName: "competitiveData", levels: [{level:6}], hide: true  },
    },
    {
        path: "/keywordDelivery",
        name: "keywordDelivery",
        component: () => import("../views/rpa/keywordDelivery.vue"),
        meta: { title: "关键词投放数据", keepAlive: true, comName: "keywordDelivery", hide: true },
    },
    {
        path: "/planningData",
        name: "planningData",
        component: () => import("../views/rpa/planningData.vue"),
        meta: { title: "计划核心数据", keepAlive: true, comName: "planningData", hide: true },
    },
    {
        path: "/crowdData",
        name: "crowdData",
        component: () => import("../views/rpa/crowdData.vue"),
        meta: { title: "人群数据", keepAlive: true, comName: "crowdData", hide: true },
    },
    {
        path: "/crowdPicture",
        name: "crowdPicture",
        component: () => import("../views/rpa/crowdPicture.vue"),
        meta: { title: "人群画像", keepAlive: true, comName: "crowdPicture", hide: true },
    },
    {
        path: "/rpaData",
        name: "rpaData",
        component: () => import("../views/rpa/rpaData.vue"),
        meta: { title: "RPA数据", keepAlive: true, comName: "rpaData" }
    },
    {
        path: "/rpaControl",
        name: "rpaControl",
        component: () => import("../views/rpa/rpaControl.vue"),
        meta: { title: "运营中台", keepAlive: true, comName: "rpaControl" }
    },
    {
        path: "/rpaAnalysisOld",
        name: "rpaAnalysisOld",
        component: () => import("../views/rpa/rpaAnalysisOld.vue"),
        meta: { title: "rpa数据分析", keepAlive: true, fullScreen: true, comName: "rpaAnalysisOld" }
    },
    {
        path: "/rpaAnalysis",
        name: "rpaAnalysis",
        component: () => import("../views/rpa/rpaAnalysis.vue"),
        meta: { title: "rpa分析", keepAlive: true, fullScreen: true, comName: "rpaAnalysis" }
    },
    {
        path: "/coreData",
        name: "coreData",
        component: () => import("../views/dataAnalysis/coreData.vue"),
        meta: { title: "核心数据分析", keepAlive: true, comName: "coreData", levels: [{level:6}] }
    },
    {
        path: "/activityBoard",
        name: "activityBoard",
        component: () => import("../views/rpa/activityBoard.vue"),
        meta: { title: "双十一销售看板", keepAlive: true, fullScreen: true, comName: "activityBoard" }
    },
    /* RPA数据 end */
    /* 营销任务系统 start */
    {
        path: "/operateTaskHome",
        name: "operateTaskHome",
        component: () => import("../views/marketing/operateTaskHome.vue"),
        meta: { title: "任务首页", keepAlive: true, comName: "operateTaskHome" }
    },
    {
        path: "/serviceTaskHome",
        name: "serviceTaskHome",
        component: () => import("../views/marketing/serviceTaskHome.vue"),
        meta: { title: "任务首页", keepAlive: true, comName: "serviceTaskHome" }
    },
    {
        path: "/establish",
        name: "establish",
        component: () => import("../views/marketing/establish.vue"),
        meta: { title: "任务建立", keepAlive: true, comName: "establish" }
    },
    {
        path: "/taskview",
        name: "taskview",
        component: () => import("../views/marketing/taskview.vue"),
        meta: { title: "我的任务", keepAlive: true, comName: "taskview" }
    },
    {
        path: "/terminatetask",
        name: "terminatetask",
        component: () => import("../views/marketing/terminatetask.vue"),
        meta: { title: "终止任务", keepAlive: true, comName: "terminatetask" }
    },
    {
        path: "/marketwork",
        name: "marketwork",
        component: () => import("../views/marketing/marketwork.vue"),
        meta: { title: "任务查看", keepAlive: true, comName: "marketwork", hide: true }
    },
    {
        path: "/staffAssignments",
        name: "staffAssignments",
        component: () => import("../views/marketing/staffAssignments.vue"),
        meta: { title: "执行查看", keepAlive: true, comName: "staffAssignments", isAdmin: true }
    },
    {
        path: "/beforehandStaffAssignments",
        name: "beforehandStaffAssignments",
        component: () => import("../views/marketing/beforehandStaffAssignments.vue"),
        meta: { title: "人员预分配", keepAlive: true, comName: "beforehandStaffAssignments", isAdmin: true }
    },
    {
        path: "/fundAllocation",
        name: "fundAllocation",
        component: () => import("../views/marketing/fundAllocation.vue"),
        meta: { title: "资金分配", keepAlive: true, comName: "fundAllocation", isAdmin: true }
    },
    {
        path: "/personalWorkbench",
        name: "personalWorkbench",
        component: () => import("../views/marketing/personalWorkbench.vue"),
        meta: { title: "个人工作台", keepAlive: true, comName: "personalWorkbench" }
    },
    {
        path: "/allocationtable",
        name: "allocationtable",
        component: () => import("../views/marketing/allocationtable.vue"),
        meta: { title: "实时任务排班", keepAlive: true, comName: "allocationtable", isAdmin: true }
    },
    {
        path: "/abnormal",
        name: "abnormal",
        component: () => import("../views/marketing/abnormal.vue"),
        meta: { title: "异常单查询", keepAlive: true, comName: "abnormal", isAdmin: true }
    },
    /* 营销任务系统 end */

    {
        path: "/deductionPoint",
        name: "deductionPoint",
        component: () => import("../views/operation/deductionPoint.vue"),
        meta: { title: "扣点运费", keepAlive: true, comName: "deductionPoint" }
    },
    /* 核销 start */
    {
        path: "/fixedWriteOff",
        name: "fixedWriteOff",
        component: () => import("../views/operation/fixedWriteOff.vue"),
        meta: { title: "固定核销", keepAlive: true, comName: "fixedWriteOff" }
    },
    {
        path: "/specialWriteOff",
        name: "specialWriteOff",
        component: () => import("../views/operation/specialWriteOff.vue"),
        meta: { title: "特殊核销", keepAlive: true, comName: "specialWriteOff" }
    },
    {
        path: "/operateWriteOff",
        name: "operateWriteOff",
        component: () => import("../views/operation/operateWriteOff.vue"),
        meta: { title: "运营核销", keepAlive: true, comName: "operateWriteOff" }
    },
    /* 核销 end */

    /* 运营利润 start */
    {
        path: "/dayProfit",
        name: "dayProfit",
        component: () => import("../views/operation/dayProfit.vue"),
        meta: { title: "SKU销售明细", keepAlive: true, comName: "dayProfit" },
    },
    {
        path: "/monthProfit",
        name: "monthProfit",
        component: () => import("../views/operation/monthProfit.vue"),
        meta: { title: "个人销售汇总", keepAlive: true, comName: "monthProfit" },
    },
    {
        path: "/teamProfit",
        name: "teamProfit",
        component: () => import("../views/operation/teamProfit.vue"),
        meta: { title: "店铺销售汇总(运营人)", keepAlive: true, comName: "teamProfit", isAdmin: true },
    },
    {
        path: "/storeProfit",
        name: "storeProfit",
        component: () => import("../views/operation/storeProfit.vue"),
        meta: { title: "店铺销售汇总(宝贝ID)", keepAlive: true, comName: "storeProfit", isAdmin: true },
    },
    {
        path: "/babyProfit",
        name: "babyProfit",
        component: () => import("../views/operation/babyProfit.vue"),
        meta: { title: "利润表(日)", keepAlive: true, comName: "babyProfit" },
    },
    {
        path: "/store_month_Details",
        name: "store_month_Details",
        component: () => import("../views/operation/store_month_Details.vue"),
        meta: { title: "店铺月销售汇总(核销)", keepAlive: true, comName: "store_month_Details", isAdmin: true },
    },
    {
        path: "/TaoAppEvaluate",
        name: "TaoAppEvaluate",
        component: () => import("../views/operation/TaoAppEvaluate.vue"),
        meta: { title: "手淘评价", keepAlive: true, comName: "TaoAppEvaluate" },
    },
    /* 运营利润 start */
    {
        path: "/dayPerformance",
        name: "dayPerformance",
        component: () => import("../views/finance/dayPerformance.vue"),
        meta: { title: "运营日绩效", keepAlive: true, comName: "dayPerformance" },
    },
    {
        path: "/monthPerformance",
        name: "monthPerformance",
        component: () => import("../views/finance/monthPerformance.vue"),
        meta: { title: "运营月绩效", keepAlive: true, comName: "monthPerformance" },
    },
    {
        path: "/teamPerformance",
        name: "teamPerformance",
        component: () => import("../views/finance/teamPerformance.vue"),
        meta: { title: "团队绩效", keepAlive: true, comName: "teamPerformance", isAdmin: true },
    },
    /* 运营利润 end */
    /* 财务收集录入统计 */
    {
        path: "/evaluation",
        name:"evaluation",
        component: () => import("../views/finance/evaluation.vue"),
        meta: { title: "评价数据", keepAlive: true, comName: "evaluation" },
    },
    {
        path: "/collector",
        name:"collector",
        component: () => import("../views/finance/collector.vue"),
        meta: { title: "收集数据", keepAlive: true, comName: "collector" },
    },
    {
        path: "/incomeAndExpenditure",
        name:"incomeAndExpenditure",
        component: () => import("../views/finance/incomeAndExpenditure.vue"),
        meta: { title: "收支数据", keepAlive: true, comName: "incomeAndExpenditure" },
    },
    {
        path: "/storeRealTimeOrder",
        name: "storeRealTimeOrder",
        component: () => import("../views/finance/storeRealTimeOrder.vue"),
        meta: { title: "实时订单", keepAlive: true, comName: "storeRealTimeOrder", levels: [{level:6}]}
    },
    {
        path: "/dailyReview",
        name: "dailyReview",
        component: () => import("../views/finance/dailyReview.vue"),
        meta: { title: "店铺日评价数据", keepAlive: true, comName: "dailyReview" }
    },
    /* 财务收集录入统计 end */

    /* 客服管理 start */
    {
        path: "/analysis_summary",
        name:"analysis_summary",
        component: () => import("../views/private_domain/analysis_summary.vue"),
        meta: { title: "数据统计", keepAlive: true, comName: "analysis_summary", isAdmin: true },
    },
    {
        path: "/order_entry",
        name:"order_entry",
        component: () => import("../views/private_domain/order_entry.vue"),
        meta: { title: "客服工作台", keepAlive: true, comName: "order_entry" },
    },
    {
        path: "/fourRefunds",
        name:"fourRefunds",
        component: () => import("../views/service/fourRefunds.vue"),
        meta: { title: "售后退款", keepAlive: true, comName: "fourRefunds" },
    },
    {
        path: "/Data_query",
        name:"Data_query",
        component: () => import("../views/service/Data_query.vue"),
        meta: { title: "数据查询", keepAlive: true, comName: "Data_query" },
    },
    {
        path: "/authorization",
        name:"authorization",
        meta: { title: "品牌授权查询", keepAlive: true, disabled: true, comName: "authorization" }
    },
    {
        path: "/authorizationReg",
        name:"authorizationReg",
        meta: { title: "品牌授权管理", keepAlive: true, disabled: true, comName: "authorizationReg" }
    },
    {
        path: "/customerData",
        name:"customerData",
        component: () => import("../views/service/customerData.vue"),
        meta: { title: "客服转化数据", keepAlive: true, comName: "customerData", isAdmin: true }
    },
    {
        path: "/itemConversion",
        name: "itemConversion",
        component: () => import("../views/service/itemConversion.vue"),
        meta: { title: "宝贝ID转化详情", keepAlive: true, comName: "itemConversion", isAdmin: true },
    },
    {
        path: "/customerConversion",
        name: "customerConversion",
        component: () => import("../views/service/customerConversion.vue"),
        meta: { title: "客服转化详情", keepAlive: true, comName: "customerConversion", isAdmin: true },
    },
    {
        path: "/storeRating",
        name: "storeRating",
        component: () => import("../views/service/storeRating.vue"),
        meta: { title: "店铺评分数据", keepAlive: true, comName: "storeRating", isAdmin: true },
    },
    {
        path: "/PXI_Rating",
        name: "PXI_Rating",
        component: () => import("../views/service/PXI_Rating.vue"),
        meta: { title: "PXI评分数据", keepAlive: true, comName: "PXI_Rating", isAdmin: true },
    },
    /* 营销客服 录入数据 start */
    {
        path: "/new_evaluation",
        name: "new_evaluation",
        component: () => import("../views/service/new_evaluation.vue"),
        meta: { title: "评价录入", keepAlive: true, comName: "new_evaluation"}
    },
    {
        path: "/collect_new_ningyu",
        name: "collect_new_ningyu",
        component: () => import("../views/service/collect_new_ningyu.vue"),
        meta: { title: "收集录入", keepAlive: true, comName: "collect_new_ningyu"}
    },
    {
        path: "/envelopes",
        name: "envelopes",
        component: () => import("../views/service/envelopes.vue"),
        meta: { title: "收支红包录入", keepAlive: true, comName: "envelopes"}
    },
    {
        path: "/healthy",
        name: "healthy",
        component: () => import("../views/service/healthy.vue"),
        meta: { title: "阿里健康录入", keepAlive: true, comName: "healthy"}
    },
    {
        path: "/financeHealthy",
        name: "financeHealthy",
        component: () => import("../views/finance/financeHealthy.vue"),
        meta: { title: "阿里健康数据", keepAlive: true, comName: "financeHealthy"}
    },
    /* 小象数据 */
    {
        path: "/collect_new_jinhua",
        name: "collect_new_jinhua",
        component: () => import("../views/service/collect_new_jinhua.vue"),
        meta: { title: "收集录入", keepAlive: true, comName: "collect_new_jinhua", levels: [{level:30}]}
    },
    {
        path: "/goldEvaluation",
        name: "goldEvaluation",
        component: () => import("../views/service/goldEvaluation.vue"),
        meta: { title: "评价录入", keepAlive: true, comName: "goldEvaluation", levels: [{level:30}]}
    },
    {
        path: "/revenueExpenditure",
        name: "revenueExpenditure",
        component: () => import("../views/service/revenueExpenditure.vue"),
        meta: { title: "收支红包录入", keepAlive: true, comName: "revenueExpenditure", levels: [{level:30}]}
    },
    /* 有墨数据 */
    {
        path: "/youmoorderEntry",
        name: "youmoorderEntry",
        component: () => import("../views/service/youmoorderEntry.vue"),
        meta: { title: "营销单录入", keepAlive: true, icon: "icon-ceshishenqing", comName: "youmoorderEntry"}
    },
    {
        path: "/order_query",
        name: "order_query",
        component: () => import("../views/service/order_query.vue"),
        meta: { title: "营销单订单修改", keepAlive: true, comName: "order_query", isAdmin: true}
    },
    {
        path: "/inkTokOrderQuery",
        name: "inkTokOrderQuery",
        component: () => import("../views/service/inkTokOrderQuery.vue"),
        meta: { title: "营销单订单查询", keepAlive: true, comName: "inkTokOrderQuery"}
    },
    {
        path: "/outerOutside",
        name: "outerOutside",
        component: () => import("../views/service/outside.vue"),
        meta: { title: "外部补单", keepAlive: true, comName: "outside" }
    },
    {
        path: "/private_order_query",
        name:"private_order_query",
        component: () => import("../views/service/private_order_query.vue"),
        meta: { title: "私域订单查询", keepAlive: true, comName: "private_order_query" },
    },
    {
        path: "/journal_edit",
        name: "journal_edit",
        component: () => import("../views/service/journal_edit.vue"),
        meta: { title: "补单修改日志", keepAlive: true, comName: "journal_edit"}
    },
    /* 营销客服 录入数据 end */


    /* 退款 start */
    {
        path: "/salesSummary",
        name:"salesSummary",
        component: () => import("../views/service/Summary.vue"),
        meta: { title: "退款数据汇总", keepAlive: true, comName: "Summary" },
    },
    {
        path: "/enter",
        name:"enter",
        component: () => import("../views/service/enter.vue"),
        meta: { title: "售后退款", keepAlive: true, comName: "enter" },
    },
    /* 退款 end */
    {
        path: "/financeMonthData",
        name: "financeMonthData",
        meta: { title: "月度数据", disabled: true}
    },
    {
        path: "/trickster",
        name: "trickster",
        component: () => import("../views/service/trickster.vue"),
        meta: { title: "骗子查询", keepAlive: true, comName: "trickster" },
    },
    {
        path: "/whitePool",
        name: "whitePool",
        component: () => import("../views/service/whitePool.vue"),
        meta: { title: "白池管理", keepAlive: true, comName: "whitePool", isAdmin: true },
    },
    {
        path: "/cloudMapData",
        name: "cloudMapData",
        meta: { title: "云图数据", disabled: true}
    },
    {
        path: "/summaryScreening",
        name: "summaryScreening",
        meta: { title: "汇总筛查", disabled: true}
    },
    {
        path: "/second_market",
        name: "second_market",
        component: () => import("../views/service/second_market.vue"),
        meta: { title: "小象二次营销", keepAlive: true, comName: "second_market" }
    },
    {
        path: "/businessMonth",
        name: "businessMonth",
        meta: { title: "生意经月数据", keepAlive: true, comName: "businessMonth" },
        component: () => import("../views/finance/businessMonth.vue")
    },
    {
        path: "/AdditionalCommission",
        name: "AdditionalCommission",
        meta: { title: "外部补单佣金价格区间", keepAlive: true, comName: "AdditionalCommission" },
        component: () => import("../views/finance/AdditionalCommission.vue")
    },
    {
        path: "/withinCommission",
        name: "withinCommission",
        meta: { title: "内部补单佣金价格区间", keepAlive: true, comName: "withinCommission" },
        component: () => import("../views/finance/withinCommission.vue")
    },
    {
        path: "/evaluationPrice",
        name: "evaluationPrice",
        meta: { title: "评价价格区间", keepAlive: true, comName: "evaluationPrice" },
        component: () => import("../views/finance/evaluationPrice.vue")
    },
    {
        path: "/collectPrices",
        name: "collectPrices",
        meta: { title: "收集价格区间", keepAlive: true, comName: "collectPrices" },
        component: () => import("../views/finance/collectPrices.vue")
    },
    {
        path: "/AlipayLiushui",
        name: "AlipayLiushui",
        meta: { title: "活动支付宝流水", keepAlive: true, comName: "AlipayLiushui" },
        component: () => import("../views/finance/AlipayLiushui.vue")
    },
    {
        path: "/cloudPicture",
        name: "cloudPicture",
        meta: { title: "云图流水", keepAlive: true, comName: "cloudPicture" },
        component: () => import("../views/finance/cloudPicture.vue")
    },
    {
        path: "/BankStatement",
        name: "BankStatement",
        meta: { title: "银行流水", keepAlive: true, comName: "BankStatement" },
        component: () => import("../views/finance/BankStatement.vue")
    },
    {
        path: "/Wangdiantong",
        name: "Wangdiantong",
        meta: { title: "旺店通发货订单明细", keepAlive: true, comName: "Wangdiantong" },
        component: () => import("../views/finance/Wangdiantong.vue")
    },
    {
        path: "/businessThrough",
        name: "businessThrough",
        meta: { title: "生意经", keepAlive: true, comName: "businessThrough" },
        component: () => import("../views/finance/businessThrough.vue")
    },
    {
        path: "/earnestMoney",
        name: "earnestMoney",
        meta: { title: "保证金", keepAlive: true, comName: "earnestMoney" },
        component: () => import("../views/finance/earnestMoney.vue")
    },
    {
        path: "/WangdiantongOriginal",
        name: "WangdiantongOriginal",
        meta: { title: "旺店通-原始退款订单", keepAlive: true, comName: "WangdiantongOriginal" },
        component: () => import("../views/finance/WangdiantongOriginal.vue")
    },
    {
        path: "/WeChatBilling",
        name: "WeChatBilling",
        meta: { title: "微信支付账单", keepAlive: true, comName: "WeChatBilling" },
        component: () => import("../views/finance/WeChatBilling.vue")
    },
    {
        path: "/AlipayStream",
        name: "AlipayStream",
        meta: { title: "售后支付宝流水", keepAlive: true, comName: "AlipayStream" },
        component: () => import("../views/finance/AlipayStream.vue")
    },
    {
        path: "/abnormalOrder",
        name: "abnormalOrder",
        meta: { title: "对账异常订单", keepAlive: true, comName: "abnormalOrder" },
        component: () => import("../views/finance/abnormalOrder.vue")
    },
    {
        path: "/soldOutGoods",
        name: "soldOutGoods",
        meta: { title: "下架宝贝查询", keepAlive: true, comName: "soldOutGoods" },
        component: () => import("../views/finance/soldOutGoods.vue")
    },
    {
        path: "/performanceSummary",
        name: "performanceSummary",
        meta: { title: "绩效汇总", disabled: true}
    },
    /* 快递账单 */
    {
        path: "/cloudWarehouse",
        name: "cloudWarehouse",
        component: () => import("../views/fastMail/cloudWarehouse.vue"),
        meta: { title: "云仓⽉快递费⽤", keepAlive: true, comName: "cloudWarehouse" }
    },
    {
        path: "/expressTemplate",
        name: "expressTemplate",
        component: () => import("../views/fastMail/expressTemplate.vue"),
        meta: { title: "快递模板", keepAlive: true, comName: "expressTemplate" }
    },
    {
        path: "/laborCost",
        name: "laborCost",
        component: () => import("../views/fastMail/laborCost.vue"),
        meta: { title: "费⽤", keepAlive: true, comName: "laborCost" }
    },
    /* erp管理 */
    {
        path: "/purchaseOrder",
        name: "purchaseOrder",
        component: () => import("../views/procurement/purchaseOrder.vue"),
        meta: { title: "采购单", keepAlive: true, comName: "purchaseOrder" }
    },
    {
        path: "/transferOrder",
        name: "transferOrder",
        component: () => import("../views/procurement/transferOrder.vue"),
        meta: { title: "调拨单查询", keepAlive: true, comName: "transferOrder" }
    },
    {
        path: "/inventorySheet",
        name: "inventorySheet",
        component: () => import("../views/procurement/inventorySheet.vue"),
        meta: { title: "盘点单查询", keepAlive: true, comName: "inventorySheet" }
    },
    {
        path: "/outsourcing",
        name: "outsourcing",
        component: () => import("../views/procurement/outsourcing.vue"),
        meta: { title: "委外出入库单", keepAlive: true, comName: "outsourcing" }
    },
    {
        path: "/outbound",
        name: "outbound",
        component: () => import("../views/procurement/outbound.vue"),
        meta: { title: "出库单查询", keepAlive: true, comName: "outbound" }
    },
    {
        path: "/warehousingOrder",
        name: "warehousingOrder",
        component: () => import("../views/procurement/warehousingOrder.vue"),
        meta: { title: "入库单查询", keepAlive: true, comName: "warehousingOrder" }
    },
    {
        path: "/purchaseAndinventory",
        name: "purchaseAndinventory",
        component: () => import("../views/procurement/purchaseAndinventory.vue"),
        meta: { title: "进销存汇总", keepAlive: true, comName: "purchaseAndinventory" }
    },
    // 运营日志分析
    // 财务成本分析 ->
    {
        path: "/requisition",
        name: "requisition",
        meta: { title: "调拨单出库", disabled: true}
    },
    {
        path: "/financeCost",
        name: "financeCost",
        meta: { title: "财务成本", disabled: true}
    },
    /*  {
       path: "/combinationQuery",
       name: "combinationQuery",
       meta: { title: "组合装查询", disabled: true}
     }, */
    {
        path: "/realTimeOrder",
        name: "realTimeOrder",
        component: () => import("../views/operation/realTimeOrder.vue"),
        meta: { title: "实时订单", keepAlive: true, comName: "realTimeOrder" }
    },
    {
        path: "/courierTimeliness",
        name: "courierTimeliness",
        meta: { title: "快递时效查询", disabled: true}
    },
    /* 营销任务管理 start */
    {
        path: "/announcement",
        name: "announcement",
        component: () => import("../views/noticeReg/announcement.vue"),
        meta: { title: "公告管理", keepAlive: true, comName: "announcement"}
    },
    {
        path: "/conference",
        name: "conference",
        component: () => import("../views/noticeReg/conference.vue"),
        meta: { title: "会议室管理", keepAlive: true, comName: "conference"}
    },
    {
        path: "/operatEperformance",
        name: "operatEperformance",
        meta: { title: "运营绩效", disabled: true}
    },
    /* 人事系统 start */
    {
        path: "/hrHome",
        name: "hrHome",
        component: () => import("../views/hr/hrHome.vue"),
        meta: { title: "招聘首页", keepAlive: true, comName: "hrHome" }
    },
    {
        path: "/resume_query",
        name: "resume_query",
        component: () => import("../views/hr/resume_query.vue"),
        meta: { title: "简历查询", keepAlive: true, comName: "resume_query" }
    },
    {
        path: "/Add_resume",
        name: "Add_resume",
        component: () => import("../views/hr/Add_resume.vue"),
        meta: { title: "简历录入", keepAlive: true, comName: "Add_resume" }
    },
    {
        path: "/interviewManagement",
        name: "interviewManagement",
        component: () => import("../views/hr/interviewManagement.vue"),
        meta: { title: "面试确认", keepAlive: true, comName: "interviewManagement" }
    },
    {
        path: "/Talent_pool",
        name: "Talent_pool",
        component: () => import("../views/hr/Talent_pool.vue"),
        meta: { title: "人才库", keepAlive: true, comName: "Talent_pool" }
    },
    {
        path: "/resume_Recyclebin",
        name: "resume_Recyclebin",
        component: () => import("../views/hr/resume_Recyclebin.vue"),
        meta: { title: "简历回收站", keepAlive: true, comName: "resume_Recyclebin" }
    },
    {
        path: "/entryReg",
        name: "entryReg",
        component: () => import("../views/hr/entryReg.vue"),
        meta: { title: "入职管理", keepAlive: true, comName: "entryReg" }
    },
    {
        path: "/Reception_Admin",
        name: "Reception_Admin",
        component: () => import("../views/hr/Reception_Admin.vue"),
        meta: { title: "前台管理", keepAlive: true, comName: "Reception_Admin" }
    },
    {
        path: "/salaryReg",
        name: "salaryReg",
        meta: { title: "薪酬管理", disabled: true}
    },
    {
        path: "/trainingReg",
        name: "trainingReg",
        meta: { title: "培训管理", disabled: true}
    },
    {
        path: "/attendanceInformation",
        name: "attendanceInformation",
        component: () => import("../views/hr/attendanceInformation.vue"),
        meta: { title: "餐补管理", keepAlive: true, comName: "attendanceInformation" }
    },
    {
        path: "/annualAnalysis",
        name: "annualAnalysis",
        component: () => import("../views/hr/annualAnalysis.vue"),
        meta: { title: "年度分析", keepAlive: true, comName: "annualAnalysis" }
    },
    /* 人事系统 end */

    /* 行政 start */
    {
        path: "/dataStatistics",
        name: "dataStatistics",
        component: () => import("../views/it/property/dataStatistics.vue"),
        meta: { title: "数据统计", keepAlive: true, comName: "dataStatistics" }
    },
    {
        path: "/xzFixedAsset",
        name: "xzFixedAsset",
        component: () => import("../views/it/property/fixedAssets/index.vue"),
        meta: { title: "固定资产", keepAlive: true, comName: "xzFixedAsset" }
    },
    {
        path: "/officeProcurement",
        name: "officeProcurement",
        component: () => import("../views/it/property/administrationPurchase/index.vue"),
        meta: { title: "办公采购", keepAlive: true, comName: "officeProcurement" }
    },
    {
        path: "/officeWarehouse",
        name: "officeWarehouse",
        component: () => import("../views/it/property/administrationWarehouse/index.vue"),
        meta: { title: "办公仓库", keepAlive: true, comName: "officeWarehouse" }
    },
    /* 行政 end */

    /* IT 管理 */
    {
        path: "/assetsHome",
        name: "assetsHome",
        component: ()=>import("../views/it/property/assetsHome.vue"),
        meta: { title: "数据统计", keepAlive: true, comName: "assetsHome" },
    },
    {
        path: "/purchaseReg",
        name: "purchaseReg",
        component: ()=>import("../views/it/property/purchase/index.vue"),
        meta: { title: "采购管理", keepAlive: true, comName: "purchaseReg" },
    },
    {
        path: "/storehouseReg",
        name: "storehouseReg",
        component: ()=>import("../views/it/property/warehouse/index.vue"),
        meta: { title: "仓库管理", keepAlive: true, comName: "storehouseReg" },
    },
    {
        path: "/sortManagement",
        name: "sortManagement",
        component: ()=>import("../views/it/property/sortManagement.vue"),
        meta: { title: "分类管理", keepAlive: true, comName: "sortManagement" }
    },
    {
        path: "/personnel",
        name: "personnel",
        component: ()=>import("../views/it/backStage/personnel.vue"),
        meta: { title: "人员管理", keepAlive: true, comName: "personnel" }
    },
    {
        path: "/userGroups",
        name: "userGroups",
        component: ()=>import("../views/it/backStage/userGroups.vue"),
        meta: { title: "用户组管理", keepAlive: true, comName: "userGroups" }
    },
    {
        path: "/storeReg",
        name: "storeReg",
        component: ()=>import("../views/it/backStage/storeReg.vue"),
        meta: { title: "店铺管理", keepAlive: true, comName: "storeReg" }
    },
    {
        path: "/storeLimitsReg",
        name: "storeLimitsReg",
        component: ()=>import("../views/it/backStage/storeLimitsReg.vue"),
        meta: { title: "客服店铺管理", keepAlive: true, comName: "storeLimitsReg" }
    },
    {
        path: "/operateStoreLimitReg",
        name: "operateStoreLimitReg",
        component: ()=>import("../views/it/backStage/operateStoreLimitReg.vue"),
        meta: { title: "用户组店铺管理", keepAlive: true, comName: "operateStoreLimitReg" }
    },
    {
        path: "/editionReg",
        name: "editionReg",
        meta: { title: "版本管理", disabled: true}
    },
    {
        path: "/authorityReg",
        name: "authorityReg",
        component: ()=>import("../views/it/backStage/authorityReg.vue"),
        meta: { title: "权限管理", keepAlive: true, comName: "authorityReg" }
    },
    {
        path: "/logReg",
        name: "logReg",
        meta: { title: "日志管理", disabled: true}
    },
    {
        path: "/databaseReg",
        name: "databaseReg",
        meta: { title: "数据库管理", disabled: true}
    },
    {
        path: "/testPush",
        name: "testPush",
        component: ()=>import("../views/it/backStage/testPush.vue"),
        meta: { title: "消息推送", keepAlive: false, comName: "testPush" }
    },
    {
        path: "/tempTest",
        name: "tempTest",
        component: () => import("../views/it/backStage/tempTest.vue"),
        meta: { title: "数据模板", keepAlive: true, comName: "tempTest", levels: [{level: 6}] }
    },
    {
        path: "/targetcostManagement",
        name: "targetcostManagement",
        component: ()=>import("../views/it/backStage/targetcostManagement.vue"),
        meta: { title: "目标费比管理", keepAlive: false, comName: "targetcostManagement" }
    },
    /* IT管理 end */
    /* 市场营销 start */
    {
        path: "/marketingAnalysis",
        name: "marketingAnalysis",
        component: () => import("../views/marketplace/marketingAnalysis.vue"),
        meta: { title: "营销分析", keepAlive: true, hide: true, comName: "marketingAnalysis" }
    },
    {
        path: "/market_costComparison",
        name: "market_costComparison",
        component: () => import("../views/marketplace/market_costComparison.vue"),
        meta: { title: "制图费用管理", keepAlive: true, comName: "market_costComparison" }
    },
    {
        path: "/teamAnalysis",
        name: "teamAnalysis",
        component: () => import("../views/marketplace/teamAnalysis.vue"),
        meta: { title: "团队分析", keepAlive: true, comName: "teamAnalysis", hide: true }
    },
    {
        path: "/processManagement",
        name: "processManagement",
        component: () => import("../views/marketplace/processManagement.vue"),
        meta: { title: "需求建立", keepAlive: true, comName: "processManagement" }
    },
    {
        path: "/taskTrace",
        name: "taskTrace",
        component: () => import("../views/marketplace/taskTrace.vue"),
        meta: { title: "需求追踪", keepAlive: false, comName: "taskTrace" }
    },
    {
        path: "/taskBoardData",
        name: "taskBoardData",
        component: () => import("../views/marketplace/taskBoardData.vue"),
        meta: { title: "需求看板数据", keepAlive: true, fullScreen: false, comName: "taskBoardData" }
    },
    {
        path: "/technologicalProcess",
        name: "technologicalProcess",
        component: () => import("../views/marketplace/technologicalProcess.vue"),
        meta: { title: "SOP流程管理", keepAlive: true, comName: "technologicalProcess" }
    },
    /* 市场营销 start */
    /* 紫春商学院 start */
    {
        path: "/instituteHome",
        name: "instituteHome",
        component: () => import("../views/institute/instituteHome.vue"),
        meta: { title: "紫春商学院", keepAlive: true, comName: "instituteHome" }
    },
    {
        path: "/questionBank",
        name: "questionBank",
        component: () => import("../views/institute/questionBank.vue"),
        meta: { title: "题库管理", keepAlive: true, comName: "questionBank" }
    },
    {
        path: "/simulation",
        name: "simulation",
        // component: () => import("../views/institute/simulation.vue"),
        meta: { title: "模拟测试", keepAlive: true, disabled: true, comName: "simulation" }
    },
    {
        path: "/formalExamination",
        name: "formalExamination",
        // component: () => import("../views/institute/formalExamination.vue"),
        meta: { title: "正式考试", keepAlive: true, disabled: true, comName: "formalExamination" }
    },
    /* 紫春商学院 end */
    /* 数据统计分析 start */
    {
        path: "/depBriefReport",
        name: "depBriefReport",
        component: () => import("../views/dataAnalysis/depBriefReport.vue"),
        meta: { title: "部门简报", keepAlive: true, comName: "depBriefReport" }
    },
    {
        path: "/perBriefReport",
        name: "perBriefReport",
        component: () => import("../views/dataAnalysis/depBriefReport.vue"),
        meta: { title: "个人简报", keepAlive: true, comName: "perBriefReport" }
    },
    /* {
        path: "/operationalData",
        name: "operationalData",
        component: () => import("../views/dataAnalysis/operationalData.vue"),
        meta: { title: "运营日志分析", keepAlive: false, comName: "operationalData" }
    }, */
    {
        path: "/operaHome",
        name: "operaHome",
        component: () => import("../views/dataAnalysis/operaHome.vue"),
        meta: { title: "运营利润分析", keepAlive: true, comName: "operaHome" }
    },
    {
        path: "/marketingAnalytics",
        name: "marketingAnalytics",
        component: () => import("../views/dataAnalysis/marketingAnalytics.vue"),
        meta: { title: "营销数据分析", keepAlive: true, comName: "marketingAnalytics" }
    },
    {
        path: "/analyse",
        name: "analyse",
        component: () => import("../views/dataAnalysis/analyse.vue"),
        meta: { title: "进销存分析", keepAlive: true, comName: "analyse" }
    },
    {
        path: "/financialCosts",
        name: "financialCosts",
        component: () => import("../views/dataAnalysis/financialCosts.vue"),
        meta: { title: "财务成本分析", keepAlive: true, disabled: true, comName: "financialCosts" }
    },
    {
        path: "/recruitmentAnalysis",
        name: "recruitmentAnalysis",
        component: () => import("../views/dataAnalysis/recruitmentAnalysis.vue"),
        meta: { title: "人事招聘分析", keepAlive: true, comName: "recruitmentAnalysis" }
    },
    {
        path: "/propertyAnalysis",
        name: "propertyAnalysis",
        component: () => import("../views/dataAnalysis/propertyAnalysis.vue"),
        meta: { title: "资产数据分析", keepAlive: true, comName: "propertyAnalysis" }
    },
    {
        path: "/activityData",
        name: "activityData",
        component: () => import("../views/eleven/activityData.vue"),
        meta: { title: "活动数据分析", keepAlive: false, fullScreen: true, comName: "activityData" }
    },
    {
        path: "/customerAnalysis",
        name: "customerAnalysis",
        component: () => import("../views/dataAnalysis/customerAnalysis.vue"),
        meta: { title: "客服数据分析", keepAlive: true, comName: "customerAnalysis" }
    },
    {
        path: "/feibiAnalysis",
        name: "feibiAnalysis",
        component: () => import("../views/dataAnalysis/feibiAnalysis.vue"),
        meta: { title: "实际费比查看", keepAlive: true, comName: "feibiAnalysis" }
    },
    {
        path: "/feibiCharts",
        name: "feibiCharts",
        component: () => import("../views/dataAnalysis/feibiCharts.vue"),
        meta: { title: "目标追踪看板", keepAlive: true, comName: "feibiCharts" }
    },
    {
        path: "/prohibitionForm",
        name: "prohibitionForm",
        component: () => import("../views/prohibitions/prohibitionForm.vue"),
        meta: { title: "违禁词管理", keepAlive: true, comName: "prohibitionForm" }
    },
    /* 达牛事业部 start */
    {
        path: "/daniuWorkbench",
        name: "daniuWorkbench",
        component: () => import("../views/daniuBusiness/daniuWorkbench.vue"),
        meta: { title: "工作台", keepAlive: true, comName: "daniuWorkbench" }
    },
    {
        path: "/multiPlatform",
        name: "multiPlatform",
        component: () => import("../views/daniuBusiness/multiPlatform.vue"),
        meta: { title: "多平台运营", keepAlive: true, comName: "multiPlatform" }
    },
    {
        path: "/beef_profit_details",
        name: "beef_profit_details",
        component: () => import("../views/daniuBusiness/beef_profit_details.vue"),
        meta: { title: "店铺利润明细(月)", keepAlive: true, comName: "beef_profit_details" }
    },
    {
        path: "/daniuStoreProfit",
        name: "daniuStoreProfit",
        component: () => import("../views/daniuBusiness/daniuStoreProfit.vue"),
        meta: { title: "店铺销售汇总(宝贝ID)", keepAlive: true, comName: "daniuStoreProfit" }
    },
    /* 达牛事业部 end */
    
    /* rpa 财务数据 start */
    {
        path: "/priceProtection",
        name: "priceProtection",
        component: () => import("../views/finance/priceProtection.vue"),
        meta: { title: "价保数据", keepAlive: true, comName: "priceProtection" }
    },
    {
        path: "/noRefundScreening",
        name: "noRefundScreening",
        component: () => import("../views/finance/noRefundScreening.vue"),
        meta: { title: "退款数据筛查", keepAlive: true, comName: "noRefundScreening" }
    },
    {
        path: "/refundOrder",
        name: "refundOrder",
        component: () => import("../views/finance/refundOrder.vue"),
        meta: { title: "erp退款订单", keepAlive: true, comName: "refundOrder" }
    },
    {
        path: "/deliveryOrNot",
        name: "deliveryOrNot",
        component: () => import("../views/finance/deliveryOrNot.vue"),
        meta: { title: "发真核查", keepAlive: true, comName: "deliveryOrNot" }
    },
    {
        path: "/externalOrder",
        name: "externalOrder",
        component: () => import("../views/finance/externalOrder.vue"),
        meta: { title: "外部订单信息", keepAlive: true, comName: "externalOrder" }
    },
    {
        path: "/wangOrder",
        name: "wangOrder",
        component: () => import("../views/finance/wangOrder.vue"),
        meta: { title: "旺店通营销订单", keepAlive: true, comName: "wangOrder" }
    }
]

export default routes;