import { h } from "vue"
import { notification, Button } from 'ant-design-vue';
import router from "@/router"

// 创建窗口弹窗
function webNotificationCreate(params) {
    
  // 创建浏览器窗口
  const key = `open${Date.now()}`;
  notification.open({
      message: params.title,
      description: params.content,
      duration: 10,
      closeIcon: false,
      style: {
          whiteSpace: "pre-wrap !important",
      },
      btn: () => h(Button, {
          type: 'primary',
          size: 'small', 
          onClick: () =>{
              notification.close(key)
              router.findComName('taskTrace')
          }
      }, {
          default: () => '去查看',
      }),
      key,
  });



  // 创建window窗口弹窗
  Notification.requestPermission(permission=>{
      try {
          new Notification(params.title, {
              body: params.content,
              icon: 'http://106.54.160.25:8585/assets/img/logoIcon.ico',// "图标路径，若不指定默认为favicon"
              requireInteraction: false,    //指定通知是否保持活性，直到用户点击或关闭。非必须，默认为false  
              renotify: true,     //新通知出现是否覆盖旧的通知，覆盖（true）则永远只显示一条通知，不覆盖（false）则会多条通知重叠。非必须，默认为tr
              tag: 'test',        //通知的分类标记（ID）。非必须，默认为空
              dir:'auto',         //文字排列方向，三个值：auto、ltr、rtl。只有Windows系统下Firefox浏览器支持...
          });
      } catch (error) {
          
      }
  });
}




export default webNotificationCreate