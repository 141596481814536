import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import Antd from 'ant-design-vue';
import dragMove from '@/assets/js/dragMove';
import 'ant-design-vue/dist/antd.css';
import axios from "axios"

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import zhCn from 'element-plus/es/locale/lang/zh-cn'

import "@/assets/js/remind"

import store from "@/store"

import Print from 'vue3-print-nb'

// 开启websocket链接
import { socketInit } from "./socket/index.js"
socketInit()
/* echarts 滚动条警告 */
// import 'default-passive-events';

var httpUrl = "https://uyun.vip"
// var httpUrl = "http://106.54.160.25:8585"

// 请求拦截, 添加token
axios.interceptors.request.use(config=>{
  // 获取token
  var token = ""
  var arr = document.cookie.split("; ")
  arr.forEach(v => { if (v.split("=")[0] == 'token') token = v.split("=")[1] })
  config.headers.token = token
  // config.headers["Content-Type"] = "application/x-www-form-urlencoded"

  // 拼接路由
  var url = config.url.match("http")
  // 无token状态跳转登录
  if (!url && config.url.indexOf("/api/user/") == -1 && !token) {
    router.push("/login")
    return config
  }
  if (!url) config.url = httpUrl + config.url
  return config
})

var app = createApp(App)
app.use(ElementPlus, {
  locale: zhCn,
}).use(store).use(Antd).use(dragMove).use(Print).use(router)

// 加载路由, loading
router.isReady().then(() => {
  setTimeout(()=>app.mount('#app'), 1000)
})

app.config.globalProperties.$axios = axios;
app.config.globalProperties.$httpUrl = httpUrl;


// 获取权限列表
function getGroups() {
  axios.post("/api/user_group/index")
  .then(res=>{
    console.log(res.data.data);
  })
}
// getGroups()