// 通知弹窗
import { Real_time, oa_Push, oa_group_Push, task_push } from "@/assets/js/remind"
import store from "@/store"
import axios from "axios"

var state = store.state

// 初始化websocket, 建立连接并进行监听
let socket
async function socketInit() {
  // 连接websocket
  socket = new WebSocket("wss://uyun.vip/wss")
  // 进行数据监听
  socket.onmessage = (e)=>
  { 
    console.log(e)
    socketChange(e)
  }
  
  // 获取socket错误日志
  socket.onerror = (err) => {
    console.log('error', err);
  }

  // 获取socket关闭日志
  socket.onclose = (close)=>{
    console.log('close', close);
  }
  return "连接成功"
}

function socketChange(e) {
  if (!e.data) return
  // 获取返回的数据
  var { data } = JSON.parse(e.data) 
  // 未返回数据不继续执行
  if (!data) return
  // 返回要弹窗的id字符串, 进行分割 传输
  let ids = ""
  try {
    data = JSON.parse(data)

    // 根据推送消息类型进行推送
    if (data.t) {
      if (data.t == 'task') {
        console.log(data)
        task_push(data)
      }

      return
    }
    // console.log(data);
    //监听data类型判断推送类型
    if(typeof(data) != 'object'){
      ids = data.toString().split(',')
      //调用日程接收
      scheduleChange()
      Real_time(ids)

    } else {
      ids = data.group_id

      //调用OA推送接收(部门推送)
      if (data.group_id) oa_group_Push(data)
      //调用OA推送接收(用户推送)
      if (data.user_id) oa_Push(data)
    }
  } catch (error) {
    console.log(error);
  }
}

//日程接收接口
function scheduleChange(){
  var read = 'n'
  var list = {}
  //日程接收接口
  axios.post('/api/schedule/schedule_op/index',{read}).then(res=>{
    res.data.data.map(v=>{
      list = v
      return list
    })
    state.unreadData.push(list)
  })
}


// 发送实时数据
var num = 0
function sendSocket(data) {
  try {
    num = 0
    socket.send(data);
  } catch (error) {
    // 如发生错误重新进行连接, 最大重连次数5次
    if (num >= 5) return
    num++ 
    // 断连后重新进行链接 延迟继续进行发送
    socketInit()
    setTimeout(sendSocket(data), 200)
    console.log(error)
  }
}


export {
  sendSocket,
  socketInit,
}