import axios from 'axios'
import { useToast } from "vue-toastification";
//吐司弹窗
const toast = useToast();
import { h , defineComponent } from 'vue'
import { message,notification,Button } from 'ant-design-vue';
import notified from "@/api/notified"
import router from '@/router'
import store from "@/store"

//日程实时提醒接口
function Real_time(ids) {
    console.log(ids)
    var { id, token } = store.state?.userInfo
    if (!ids || !token || !ids.find(v=>v==id)) return
    var instanceNotification = null
    // 配置浏览器内置弹窗
    // 声明一个通知的实例
    Notification.requestPermission(permission=>{
        if (permission === 'granted') {
            console.log('用户同意授权');
            // 随时可以显示通知
            // 创建通知  通知配置
            // renotify：相同
            // 默认值为false，chorme下相同tag的通知不替换，还是老的通知
            // 设置为true, 两个相同tag的通知，新通知替换之前旧的通知。
            // 注意：使用renotify，必须要同时设置tag选项，否则将会报错。
            //日程推送弹窗
            var push = 'n'
            axios.post('/api/schedule/schedule_op/index',{push}).then(resault => {
                resault.data.data.map(v=>{
                    axios.post('/api/schedule/schedule_op/edit',{id:v.id,push:'y'})
                    var { dis_name, content, finish_time, type, op_name, cc_name, exe_name , log_time } = v
                    var title = "您收到一条日程"
                    var str = dis_name
                    var op = op_name
                    if (type == "完成") str += '已完成日程\n完成内容:' + content + '\n完成时间:' + finish_time; 
                    else if (type == "提交") {str += '在日程上提交了一次!' + '\n' + log_time + '  '; title =  '你收到一个日程提交'}
                    else if (type == "评价") {str += '在日程上给' + exe_name + '评价了!' + '\n' + log_time + '  '; title = exe_name + '收到一个评价'}
                    else if (type == "点赞") {str += '在日程上给' + exe_name + '点了个赞!' + '\n' + log_time + '  '; title = exe_name + '收到一个点赞'}
                    else if (type == "取消点赞") {str += content}
                    else if (type == "修改") {str += '修改了一条日程' + '\n' + log_time + '  '; title = '您的日程有变'}
                    else if (type == "删除") {str += '删除了一条日程' + '\n' + log_time + '  '; title = '您的日程有变'}
                    else if (type == "打回") {str += '打回了一条日程' + '\n' + log_time + '  '; title = '您的日程有变'}
                    else if (type == "重新开始") {str += '重新开始了一条日程' + '\n' + log_time + '  '; title = '您的日程有变'}
                    else if (type == "催促") {str += '催促' + exe_name + '尽快完成日程' + '\n' + log_time + '  '; title = '日程追踪'}
                    else if (type == "取消日程") {str += '取消了日程' + '\n' + log_time + '  '; title = '您的日程有变'}
                    else if (type == "聊天") {str += '向' + exe_name + '发送一个信息' + '\n' + log_time + '  '; title = '你有新的消息'}
                    else if (type == "分配") {str += '给' + exe_name + '分配一个日程' + '\n' + log_time + '  '; title = '你有新的消息'}
                    else if (type == "提醒") {'请尽快完成日程' + '\n' + log_time + '  '; title = '系统提示'}
                    else if (type == "分配催促") {str += '催促你尽快分配日程' + '\n' + log_time + '  '; title = '日程分配'}
                    else str += '向' + exe_name + '发送了一个新的日程\n' + '内容为' + content
                    instanceNotification = new Notification(title, {
                        body: str,
                        icon: 'http://106.54.160.25:8585/assets/img/logoIcon.ico',// "图标路径，若不指定默认为favicon"
                        requireInteraction: false,    //指定通知是否保持活性，直到用户点击或关闭。非必须，默认为false  
                        renotify: true,     //新通知出现是否覆盖旧的通知，覆盖（true）则永远只显示一条通知，不覆盖（false）则会多条通知重叠。非必须，默认为tr
                        tag: 'test',        //通知的分类标记（ID）。非必须，默认为空
                        dir:'auto',         //文字排列方向，三个值：auto、ltr、rtl。只有Windows系统下Firefox浏览器支持...
                    });
                })
            })
            if (!instanceNotification) return
            // 通知显示的时候被触发
            instanceNotification.onshow = function () {
                console.log("显示通知");
            };
            // 用户点击通知时被触发
            instanceNotification.onclick = function () {
                router.findComName("TaskTracking",{},true)
                instanceNotification.close();
            };
            // 用户关闭通知时被触发
            instanceNotification.onclose = function () {
                console.log("通知关闭");
            };
            // 通知遇到错误时被触发
            instanceNotification.onerror = function () {
                console.log('错误');
            };
        } else if (permission === 'default') {
                console.log('用户关闭授权,可以再次请求授权');
        } else {
                console.log('用户拒绝授权,不能显示通知');
        }
    });
    var push = 'n'
    axios.post('/api/schedule/schedule_op/index',{push}).then(resault => {
        resault.data.data.map(v=>{
            axios.post('/api/schedule/schedule_op/edit',{id:v.id,push:'y'})
            var { dis_name, content, finish_time, type, op_name,cc_name,exe_name , log_time} = v
            var title = "您收到一条日程"
            var str = dis_name
            var op = op_name
            if (type == "完成") str += '已完成日程\n完成内容:' + content + '\n完成时间:' + finish_time; 
            else if (type == "提交") {str += '在日程上提交了一次!' + '\n' + log_time + '  '; title =  '你收到一个日程提交'}
            else if (type == "评价") {str += '在日程上给' + exe_name + '评价了!' + '\n' + log_time + '  '; title = exe_name + '收到一个评价'}
            else if (type == "点赞") {str += '在日程上给' + exe_name + '点了个赞!' + '\n' + log_time + '  '; title = exe_name + '收到一个点赞'}
            else if (type == "取消点赞") {str += content }
            else if (type == "修改") {str += '修改了一条日程' + '\n' + log_time + '  '; title = '您的日程有变'}
            else if (type == "删除") {str += '删除了一条日程' + '\n' + log_time + '  '; title = '您的日程有变'}
            else if (type == "打回") {str += '打回了一条日程' + '\n' + log_time + '  '; title = '您的日程有变'}
            else if (type == "重新开始") {str += '重新开始了一条日程' + '\n' + log_time + '  '; title = '您的日程有变'}
            else if (type == "催促") {str += '催促' + exe_name + '尽快完成日程' + '\n' + log_time + '  '; title = '日程追踪'}
            else if (type == "取消日程") {str += '取消了日程' + '\n' + log_time + '  '; title = '您的日程有变'}
            else if (type == "聊天") {str += '向' + exe_name + '发送一个信息' + '\n' + log_time + '  '; title = '你有新的消息'}
            else if (type == "分配") {str += '给' + exe_name + '分配一个日程' + '\n' + log_time + '  '; title = '你有新的消息'}
            else if (type == "提醒") {'请尽快完成日程' + '\n' + log_time + '  '; title = '系统提示'}
            else if (type == "分配催促") {str += '催促你尽快分配日程' + '\n' + log_time + '  '; title = '日程分配'}
            else str += '向' + exe_name + '发送了一个新的日程\n' + '内容为' + content
            const key = `open${Date.now()}`;
            notification.open({
                message: title,
                description: str,
                duration: 10,
                closeIcon: false,
                style: {
                    whiteSpace: "pre-wrap !important",
                },
                btn: () => h(Button, {
                    type: 'primary',
                    size: 'small', 
                    onClick: () =>{
                        notification.close(key)
                        // router.findComName('TaskTracking')
                    }
                }, {
                    default: () => '去查看',
                }),
                key,
            });
        })
        store.commit("pushUnreadData", resault.data.data)
    }).catch(error=>{
        console.log(error);
    })
}


//oa推送时时提醒接口(用户推送)
function oa_Push(gds) {
    var user_id = store.state.userInfo.user_id + ""
    let ids = gds.user_id.toString().split(",")

    if(!ids.includes(user_id)) return
    // 配置浏览器内置弹窗
    // 声明一个通知的实例
    NotifyWeb(gds.oa_id)
}

//oa推送时时提醒接口(部门推送)
function oa_group_Push(gds) {
    var group_id = store.state.userInfo.auth_group[0].group_id + ""
    let ids = gds.group_id.toString().split(",")
    if(!ids.includes(group_id)) return
    // 配置浏览器内置弹窗
    // 声明一个通知的实例
    NotifyWeb(gds.oa_id)
}


//OA推送浏览器弹窗方法封装
function NotifyWeb(id) {
    console.log(id);
    var instanceNotification = null
    Notification.requestPermission(permission=>{
        if (permission === 'granted') {
            console.log('用户同意授权');
            // 随时可以显示通知
            // 创建通知  通知配置
            // renotify：相同
            // 默认值为false，chorme下相同tag的通知不替换，还是老的通知
            // 设置为true, 两个相同tag的通知，新通知替换之前旧的通知。
            // 注意：使用renotify，必须要同时设置tag选项，否则将会报错。
            //oa推送弹窗
            axios.post('/api/push/oa_push/index', {id}).then(resault => {
                resault.data.data.data.map(v=>{
                    var { model, type , user_id , group_id, content, url, title , log_time , url } = v
                    //解析接口返回url参数
                    // const a = JSON.parse(url)
                    // const b = a.params
                    var str = ''
                    str += content + '\n' + log_time; 
                    const key = `open${Date.now()}`;
                    notification.open({
                        message: model,
                        description:str,
                        btn: h( 
                            Button,
                            {
                                type: 'primary',
                                size: 'small',
                                //判断url是否有值 有值处理数据做跳转,没有值默认关闭通知
                                // onClick: () => a?router.findComName(a.url,b):notification.close(key),
                                onClick: () => notification.close(key),
                            },
                            ()=>'查看',
                        ),
                        style: {
                            whiteSpace: "pre-wrap !important",
                        },
                        key,
                    });
                    instanceNotification = new Notification(title, {
                        body: str,
                        icon: 'https://yunque.uyun.vip/favicon.ico',     // "图标路径，若不指定默认为favicon"
                        requireInteraction: false,    //指定通知是否保持活性，直到用户点击或关闭。非必须，默认为false  
                        renotify: true,     //新通知出现是否覆盖旧的通知，覆盖（true）则永远只显示一条通知，不覆盖（false）则会多条通知重叠。非必须，默认为tr
                        tag: 'test',        //通知的分类标记（ID）。非必须，默认为空
                        dir:'auto',         //文字排列方向，三个值：auto、ltr、rtl。只有Windows系统下Firefox浏览器支持...
                    });
                })
            })
            if (!instanceNotification) return
            // 通知显示的时候被触发
            instanceNotification.onshow = function () {
                console.log("显示通知");
            };
            // 用户点击通知时被触发
            instanceNotification.onclick = function () {
                router.findComName("TaskTracking",{},true)
                instanceNotification.close();
            };
            // 用户关闭通知时被触发
            instanceNotification.onclose = function () {
                console.log("通知关闭");
            };
            // 通知遇到错误时被触发
            instanceNotification.onerror = function () {
                console.log('错误');
            };
        } else if (permission === 'default') {
                console.log('用户关闭授权,可以再次请求授权');
        } else {
                console.log('用户拒绝授权,不能显示通知');
        }
    })
}

// 流程任务推送
async function task_push(params) {
    try {
        var { id, token } = store.state?.userInfo
        let { uid, tid } = params
        let ids = uid.split(",").map(v=>parseInt(v))
        if (!ids.includes(id)) return
        

        let res = await axios.post("/api/push/push_log/index", {id: tid})
        let data = res.data.data.data[0]

        // 获取任务 推送信息内容 修改消息推送为已推送
        if (data.sid) {
            axios.post("/api/task/task_op/index", { sid: data.sid, op_uid: id }).then(result=>{
                let d = result.data.data[0]
                if (d) {
                    axios.post("/api/task/task_op/edit", {id: d.id, push: 'y'})
                }
            })
        }
        
        let { title, content } = data
        notified({title, content})
    } catch (error) {
        
    }

    // let title = `任务创建提醒`
    // let content = `${data.created_user_name}创建任务, 提及了您`
    
}

export {Real_time,oa_Push,oa_group_Push,task_push}
