// vue3中创建store实例对象的方法createStore()按需引入
import { createStore } from 'vuex'
import axios from "axios"

export default createStore({
  state: {
    userInfo: JSON.parse(localStorage.getItem("userInfo")),
    cacheMenus: [],
    unreadData: []
    // 初始化menu
  },
  mutations: {
    updateUserInfo(state, isClear) {
      // 修改用户信息, 参数2 是否清除用户信息
      if (isClear) return state.userInfo = null
      var user = JSON.parse(localStorage.getItem("userInfo"))
      state.userInfo = user
    },

    setUserInfo(state, info) {
      state.userInfo = info
      localStorage.setItem("userInfo", JSON.stringify(info))
    },

    setCacheMenus(state, menus) {
      var home = {
        name: "indexHome",
        path: "/",
        title: "主页",
        keepAlive: true,
        key: "sub-0",
        icon: "icon-icon_yingyongguanli",
        comName: 'indexHome'
      }

      if (!menus?.length) menus = [home]

      if (!menus.find(v=>v.title == '主页')) {
        menus.unshift(home)
      }
      
      // 设置路由缓存
      state.cacheMenus = menus.filter(v=>!v.fullScreen)
      sessionStorage.setItem("menu", JSON.stringify(menus))
    },
    
    // 更新缓存路由
    updateCacheMenus(state, item) {
      state.cacheMenus = state.cacheMenus.map(v=>{
        if (v.comName == item?.comName) {
          v = { ...v, ...item }
        }
        return v
      })
    },
    
    pushUnreadData(state, arr) {
      state.unreadData.push([...arr])
    },
    updateUnreadData(state, arr) {
      state.unreadData = arr
    }
  },
  actions: {
    // 获取用户信息
    userInfo (context) {
      // 获取token
      var token = ""
      var arr = document.cookie.split("; ")
      arr.forEach(v => { if (v.split("=")[0] == 'token') token = v.split("=")[1] })
      
      axios.post('/api/user/getUserInfo',{token}).then(e => {
        const info = e.data.data.userinfo
        context.commit('setUserInfo', info)
      })
    }
  },
  getters: {
  },
  modules: {
  }
})

